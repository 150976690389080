import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {ILoanPromiseAdmin, IUser, LoanPromiseListItem} from '../application/types'

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  public users$: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([])
  /**
   * Interested parties may subscribe to this to have a short list
   * of loan promises to look at.
   */
  public loanPromiseList: BehaviorSubject<LoanPromiseListItem[]> = new BehaviorSubject<LoanPromiseListItem[]>([])

  /**
   * Keep a local representation of all loan promises
   */
  private loanPromises: LoanPromiseListItem[] = []

  /**
   * When was the last time we fetched data (loan promise list)
   */
  private lastFetchTime: number = 0

  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * Fetch existing loan promise
   * @param id - The ID of the promise
   */
  public fetch(id: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/${id}`
    return this.httpClient.get<any>(url)
  }

  public delete(id: string): Observable<void> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.delete<void>(url).pipe(
      map(() => {
        this.loanPromises = this.loanPromises.filter(lp => lp['loan-promise-id'] !== id)
        this.loanPromiseList.next(this.loanPromises)
        return
      })
    )
  }

  public list(): Observable<Array<LoanPromiseListItem>> {
    const url = `${environment.loanServiceUrl}/promise/list?from=${this.lastFetchTime}`
    return this.httpClient.get<LoanPromiseListItem[]>(url)
      .pipe(
        map(list => {
          this.loanPromises = list
          this.loanPromiseList.next(this.loanPromises)
          this.lastFetchTime = new Date().getTime()
          this.getUsers()
          return this.loanPromises
        })
      )
  }

  /**
   * Update who is assigned
   */
  public update(id: string, data: ILoanPromiseAdmin): Observable<LoanPromiseListItem> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.put<LoanPromiseListItem>(url, data)
  }

  /**
   * Functions that are used by admin and should be moved to
   * an admin service eventually
   */

  /**
   * Get the UC as HTML
   */
  public getHtml(personNummer: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/reports/${personNummer}`
    return this.httpClient.get<Array<any>>(url)
  }

  /**
   * Fetch a list of users, send them on the sub if not already
   * fetched.
   */
  private getUsers(): void {
    const url = `${environment.commonServiceUrl}/users`
    if (this.users$.getValue().length === 0) {
      this.httpClient.get<IUser[]>(url).subscribe((users: IUser[]) => {
        const filtered = users.filter(u => {
          return u.roles.indexOf('admin') !== -1 && u.roles.indexOf('developer') === -1
        })
        this.users$.next(filtered)
      })
    }
  }
}
